var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg fill-height" },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          model: {
            value: _vm.dialogMaxSessions,
            callback: function($$v) {
              _vm.dialogMaxSessions = $$v
            },
            expression: "dialogMaxSessions"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Telas excedida ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "Você atingiu o número maximo de telas (sessões) ativas. Você pode deslogar da ultima sessão logada e entrar."
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogMaxSessions = false
                        }
                      }
                    },
                    [_vm._v(" FECHAR ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "logout-from-previous-session",
                        color: "primary",
                        outline: ""
                      },
                      on: { click: _vm.loginWithLogoutLastSession }
                    },
                    [_vm._v(" Deslogar da ultima sessão ativa e entrar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "fill-height ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "fill-height pa-0 ma-0",
              attrs: { align: "center", justify: "center" }
            },
            [
              !_vm.isLoading
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-5 ma-1 card-login",
                      attrs: { elevation: "17", raised: "", width: "380px" }
                    },
                    [
                      _c("v-container", { staticClass: "ma-0 logo-wrapper" }, [
                        _c("img", {
                          attrs: { src: require("../assets/mappa_logo.png") }
                        })
                      ]),
                      _c("v-card-title", [_vm._v("Login")]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "login-form",
                              attrs: {
                                name: "login-form",
                                novalidate: "",
                                value: _vm.valid
                              },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.validateLogin(false)
                                }
                              }
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "pa-0", attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mx-0 mt-4",
                                        attrs: {
                                          id: "username",
                                          name: "username",
                                          color: "accent",
                                          label: "E-mail",
                                          outlined: "",
                                          "error-messages": _vm.usernameErrors
                                        },
                                        model: {
                                          value: _vm.form.username,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "username", $$v)
                                          },
                                          expression: "form.username"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mx-0",
                                        attrs: {
                                          id: "password",
                                          name: "password",
                                          "append-icon": _vm.showPassword
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.showPassword
                                            ? "password"
                                            : "text",
                                          label: "Senha",
                                          outlined: "",
                                          "error-messages": _vm.passwordErrors,
                                          color: "accent"
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            _vm.showPassword = !_vm.showPassword
                                          }
                                        },
                                        model: {
                                          value: _vm.form.password,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "password", $$v)
                                          },
                                          expression: "form.password"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-0 my-2",
                                          attrs: {
                                            id: "login-submit",
                                            block: "",
                                            color: "accent",
                                            type: "submit",
                                            loading: _vm.validatingLogin
                                          }
                                        },
                                        [_vm._v(" Entrar ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "my-4" }),
                      _c(
                        "v-card-actions",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-container",
                            { staticClass: "pa-0 pt-1", attrs: { fluid: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-0 mb-4",
                                  attrs: {
                                    href: "https://software.mappa.ag/trial",
                                    small: "",
                                    block: "",
                                    text: "",
                                    color: "primary"
                                  }
                                },
                                [_vm._v(" Não sou cadastrado ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-0 mt-4",
                                  attrs: {
                                    to: "/recover-password",
                                    small: "",
                                    block: "",
                                    text: "",
                                    color: "primary"
                                  }
                                },
                                [_vm._v(" Esqueci minha senha ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("authorize-scope-form", {
        ref: "authorizeScopeForm",
        attrs: { "authorize-post-url": _vm.authorizePostUrl }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
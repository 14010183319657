<template>
  <div
    class="bg fill-height"
  >
    <v-dialog
      v-model="dialogMaxSessions"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title class="headline">
          Telas excedida
        </v-card-title>
        <v-card-text>Você atingiu o número maximo de telas (sessões) ativas. Você pode deslogar da ultima sessão logada e entrar.</v-card-text>
        <v-card-actions>  
          <v-btn
            color="error"
            text
            @click="dialogMaxSessions = false"
          >
            FECHAR
          </v-btn>                     
          <!-- <v-btn
            color="secondary"
            text
            @click="contact"
          >
            Contactar o comercial
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn
            id="logout-from-previous-session"
            color="primary"
            outline
            
            @click="loginWithLogoutLastSession"
          >
            Deslogar da ultima sessão ativa e entrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <v-container
      fluid
      class="fill-height ma-0 pa-0"
    >
      <v-row
        align="center"
        justify="center"
        class="fill-height pa-0 ma-0"
      >
        <v-card
          v-if="!isLoading"
          elevation="17"
          raised
          class="pa-5 ma-1 card-login"
          width="380px"          
        >

          <v-container class="ma-0 logo-wrapper">
            <img src="../assets/mappa_logo.png"/>
          </v-container>

          <v-card-title>Login</v-card-title>
          <v-card-text>
            <v-form
              name="login-form"
              novalidate
              :value="valid"
              ref="login-form"
              @submit.prevent="validateLogin(false)"
            >
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  class="ma-0"
                >
                  <v-text-field
                    id="username"
                    name="username"
                    color="accent"
                    label="E-mail"
                    outlined
                    v-model="form.username"
                    :error-messages="usernameErrors"
                    class="mx-0 mt-4"
                  />
                </v-row>
                <v-row
                  class="ma-0"
                >
                  <v-text-field
                    id="password"
                    name="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'password': 'text' "
                    label="Senha"
                    outlined
                    :error-messages="passwordErrors"
                    color="accent"
                    v-model="form.password"
                    class="mx-0"
                     @click:append="showPassword = !showPassword"

                  />
                </v-row>
                <v-row
                  class="ma-0"
                >
                  <v-btn
                    id="login-submit"
                    block
                    color="accent"
                    type="submit"
                    :loading="validatingLogin"
                    class="mx-0 my-2"
                  >
                    Entrar
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider
            class="my-4"
          >

          </v-divider>
          <v-card-actions
            class="pa-0"
          >
            <v-container
              fluid
              class="pa-0 pt-1"
            >
              <v-btn
                href="https://software.mappa.ag/trial"
                small
                block
                text
                color="primary"
                class="mx-0 mb-4"
              >
                Não sou cadastrado
              </v-btn>
              <v-btn
                to="/recover-password"
                small
                block
                text
                color="primary"
                class="mx-0 mt-4"
              >
                Esqueci minha senha
              </v-btn>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>

    <authorize-scope-form
      ref="authorizeScopeForm"
      :authorize-post-url="authorizePostUrl"
    />
  </div>
</template>

<script>
  import {arraysEqual, objectToFormData} from "../helpers/utils"
  import AuthorizeScopeForm from "../components/AuthorizeScopeForm"
  import {LoginError, MaxSessionsReached, TooManyFailedLoginAttempts} from "../helpers/auth-horus"
  import {NOTIFICATION_TYPES, NotificationOptions} from "../helpers/notify"
  import {mapActions, mapGetters} from "vuex"
  import {
    GET_AUTHORIZE_SCOPE_PARAMS,
    GET_CLIENT_ID,
    GET_FULL_STATE,
    GET_IS_OAUTH_STATE_VALID,
    GET_SCOPE,
    SET_OAUTH_STATE,
  } from "../store/modules/oauth"
  import {PLATFORM_URL} from "../globals"
  import required from "vuelidate/src/validators/required"
  import {validationMixin} from "vuelidate"

  export default {
    name: "Login",
    mixins: [validationMixin],
    components: {AuthorizeScopeForm},
    data: () => ({
      valid: false,
      form: {
        username: null,
        password: null,
      },
      showPassword: true,

      isLoading: true,
      validatingLogin: false,

      climateLoginUrl: `${process.env.VUE_APP_BACKEND_URL}/oauth-client/login/climate/`,
      dialogMaxSessions: false,
    }),
    validations() {
      return {
        form: {
          username: {
            required
          },
          password: {
            required
          }
        }
      }
    },
    methods: {
      contact() {
        this.dialogMaxSessions = false;
        this.$notify(new NotificationOptions({
          message: 'Por favor entre em contato conosco pelo chat.',
          type: NOTIFICATION_TYPES.INFO,
        }))
      },
      loginWithLogoutLastSession() {
        this.dialogMaxSessions = false;
        this.validateLogin(true)
      },
      validateLogin(logoutLastSession) {
        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        if (!this.$refs["login-form"].validate()) return


        this.validatingLogin = true
        const username = `${this.form.username}`
        const password = `${this.form.password}`

        this.$apiAuth.loginUser(username, password, logoutLastSession)
          .then(this._loginSuccessful)
          .catch(this._loginError)
      },
      _loginSuccessful() {
        // Check if the requested scopes is already authorized by the user,
        //  if that is the case, just call `authorizeScope` request
        this.isLoading = true
        this.$apiAuth.checkAuthorizedScopes(this.oauthClientId).then(
          (scopes) => {
            let oauthScopeList = this.oauthScope.split(" ")
            oauthScopeList = oauthScopeList.filter(item => !["openid"].includes(item))

            if (!arraysEqual(scopes, oauthScopeList)) {
              // Redirect to user consent authorization
              this.$router.replace("/authorize/")
            } else {
              const formDataInput = objectToFormData(this.oauthFullState)
              this.$nextTick(() => {
                this.$refs["authorizeScopeForm"].setFormData(formDataInput)
                  .then(() => {
                    this.$refs["authorizeScopeForm"].submitForm()
                  })
              })
            }
          },
        )
      },
      _loginError(err) {
        let message = "Ops, erro inesperado aconteceu. Por favor entre em contato conosco pelo chat."
        if (err instanceof LoginError) {
          message = "E-mail ou senha inválido(s)"
        } else if (err instanceof MaxSessionsReached) {
          this.validatingLogin = false
          message = "Número máximo de telas excedida"
          this.dialogMaxSessions = true
          return
        } else if (err instanceof TooManyFailedLoginAttempts) {
          message = "Excedido número de tentativas de login, tente novamente mais tarde"
        }

        this.$notify(new NotificationOptions({
          message: message,
          type: NOTIFICATION_TYPES.ERROR,
        }))
        this.validatingLogin = false
      },
      ...mapActions({
        setOauthState: SET_OAUTH_STATE,
      }),
    },
    computed: {
      authorizePostUrl() {
        return this.$apiAuth.createUrl(this.$apiAuth.authorizePath)
      },
      usernameErrors() {
        const errors = []
        if (!this.$v.form.username.$dirty) return errors
        !this.$v.form.username.required && errors.push("Campo obrigatório")
        return errors
      },
       passwordErrors() {
        const errors = []
        if (!this.$v.form.password.$dirty) return errors
        !this.$v.form.password.required && errors.push("Campo obrigatório")
         return errors
      },

      ...mapGetters({
        oauthAuthorizeScopeParams: GET_AUTHORIZE_SCOPE_PARAMS,
        oauthClientId: GET_CLIENT_ID,
        oauthScope: GET_SCOPE,
        oauthFullState: GET_FULL_STATE,
        isOauthStateValid: GET_IS_OAUTH_STATE_VALID,
      }),
    },
    mounted() {
      const finalQuery = this.$route.query
      this.setOauthState(finalQuery)

      if (!this.isOauthStateValid) {
        let loginUrl = `${PLATFORM_URL}/login`

        // Verify if the first_login flag is set
        //  this flag SHOULD only be set on the verify-email
        //  route.
        if (this.$route.query["fl"]) {
          loginUrl += "?fl=1"
        }

        window.location.replace(loginUrl)
        return
      }

      const login_hint = this.oauthFullState.login_hint
      if (login_hint) {
        this.form.username = login_hint
      }

      this.$apiAuth.validateSessionCode()
        .then(isValid => {
          if (isValid) {
            // If is valid, the user is already logged
            this._loginSuccessful()
          } else {
            this.isLoading = false
          }
        })
    },
  }
</script>

<style scoped lang="scss">
  @import "../sass/mixins";

  .bg {
    background-image: url("../assets/login/background_image.jpg");
    background-size: cover;
  }

  .card-login {
    max-width: 380px;

    @include display-xs-only {
      width: 100% !important;
      min-height: 100% !important;
      padding: 10px;
      margin: 0px !important;
    }
  }

  .logo-wrapper {
    text-align: center;
  }

  .logo-wrapper img {
    height: 70px;
  }

</style>
